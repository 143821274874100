
let initTabsOffert=0;
let panel=document.querySelector("#nasza-oferta .tabs__panels");

if(document.querySelector("#individualExpeditions .expeditions__wrapper")){
    panel.style.minHeight="auto";
    panel.style.minHeight=document.querySelector("#individualExpeditions .expeditions__wrapper").offsetHeight+"px";
}

function createSimpleElemnts(list, wrapper, classN) {

    let newFullWidthOuther = document.createElement("div");
    newFullWidthOuther.className = "swiper product__swiper fullWidth__outer";

    let newSwiperWrapper = document.createElement("div");
    newSwiperWrapper.className = "swiper-wrapper expeditions__wrapper fullWidth__wrapper";


    let sbNext = document.createElement("div");
    let sbPrev = document.createElement("div");

    sbNext.className = "swiper-button-next";
    sbPrev.className = "swiper-button-prev";

    newFullWidthOuther.prepend(sbNext);
    newFullWidthOuther.prepend(sbPrev);
    newFullWidthOuther.prepend(newSwiperWrapper);

    let time = 10;
    let products = list;
    for (let i = 0; i < products.length; i++) {

        let product = products[i];
        time += 80;

        let elLi = document.createElement("div");
        elLi.className = "swiper-slide product__slide product__item product__item--op0";
        newSwiperWrapper.appendChild(elLi);


        let elA = document.createElement("a");
        elA.className = `product__link`;
        elA.href = product.url;
        elLi.appendChild(elA);

        if (product.nowosc == "1") {
            let elN = document.createElement("span");
            elN.className = "product__new";
            elN.textContent = "Nowość"
            elA.appendChild(elN);
        }
        if (product.ostatnie == "1") {
            let elImageL = document.createElement("span");
            elImageL.className = "product__last";
            elImageL.innerText = "Ostatnie miejsca";
            elA.appendChild(elImageL);
        }
        if (product.brak_miejsc == "1") {
            let elImageL = document.createElement("img");
            elImageL.className = "product__last";
            elImageL.src = "/szablony/public/img/ico/brak-miejsc.svg";
            elImageL.alt = "Brak miejsc";
            elA.appendChild(elImageL);
        }


        let prFigure = document.createElement("figure");
        prFigure.className = "product__figure";
        elA.appendChild(prFigure);

        if (product.zdjecie) {
            let prImage = document.createElement("img");
            prImage.src = product.zdjecie;
            prImage.alt = "";
            prFigure.appendChild(prImage);
        }

        let elPrI = document.createElement("div");
        elPrI.className = "product__info";
        elA.appendChild(elPrI);

        let elPrtop = document.createElement("div");
        elPrtop.className = "product__top";
        elPrI.appendChild(elPrtop);
        let elPrB = document.createElement("div");
        elPrB.className = "product__bottom";
        elA.appendChild(elPrB);

        if (product.nazwa) {
            let elh3 = document.createElement("h3");
            elh3.textContent = product.nazwa;
            elh3.className = "product__title";
            elPrI.appendChild(elh3);
        }

        if (product.kraje) {

            const expression = product.kraje;
            const match = expression.match(/<img[^>]+src="([^"]+)"[^>]*>(.*)/);
            let elS1 = document.createElement("div");
            elS1.className = "product__country";
            let elS1Name = document.createElement("span");
            let countryName = expression;
            let imgTag = "";
            if (match) {
                imgTag = match[1]; // Tag <img ... />
                countryName = match[2].trim();
            }

            elS1Name.textContent = countryName;
            if (imgTag != "") {
                let krajImage = document.createElement("img");
                krajImage.alt = "";
                krajImage.width="30";
                krajImage.src = imgTag;
                elS1.appendChild(krajImage);
            }
            elS1.appendChild(elS1Name);
            elPrtop.appendChild(elS1);
        }

        if (product.trudnosc) {
            let prImage = document.createElement("img");
            if (product.trudnosc == "1") {
                prImage.src = "/szablony/public/img/ico/ico-level-1.svg";
            }
            if (product.trudnosc == "2") {
                prImage.src = "/szablony/public/img/ico/ico-level-2.svg";
            }
            if (product.trudnosc == "3") {
                prImage.src = "/szablony/public/img/ico/ico-level-3.svg";
            }

            prImage.alt = "";
            prImage.className = "product__level";
            elPrtop.appendChild(prImage);
        }

        if (product.termin) {
            let elS1 = document.createElement("div");
            let elS1Name = document.createElement("span");
            elS1.className = "product__calendar";
            elS1Name.textContent = product.termin;

            if (product.terminyHtml) {
                elS1.className = "product__calendar otherTherm__wrapper";
                elS1.innerHTML = product.terminyHtml
            }
            elS1.prepend(elS1Name);
            elPrB.appendChild(elS1);
        }

        let elBottomRight = document.createElement("div");
        elBottomRight.className = "product__right";
        elPrB.appendChild(elBottomRight);

        if (product.dni) {
            let elS1 = document.createElement("span");
            elS1.textContent = product.dni;
            elS1.className = "product__dni";
            elBottomRight.appendChild(elS1);
        }
        if (product.cena) {

            let elS1Div = document.createElement("div");
            let elS1 = document.createElement("strong");
            let elS11 = document.createElement("span");
            let elS12 = document.createElement("span");
            elS1Div.className = "product__price";
            elS1Div.textContent="od";
            elS1Div.appendChild(elS1);

            const expression = product.cena;
            const matches = [...expression.matchAll(/<span>([^<]+)<\/span>/g)];
            if (matches.length > 0) {
                let firstValue = matches[0][1].trim();
                let secondValue = matches[1] ? matches[1][1].trim() : null;
                elS11.textContent =firstValue;

                elS1.appendChild(elS11);
                if (secondValue) {
                    elS12.textContent = secondValue;
                    elS1.appendChild(elS12);
                }

                elBottomRight.appendChild(elS1Div);
            }
        }

        let elBtnHeart = document.createElement("button");
        elBtnHeart.className = "btn--heart";
        elBtnHeart.setAttribute("data-id",product.wycieczka);
        elBtnHeart.setAttribute("aria-label", "Dodaj do ulubionych");
        if (product.ulubione == "1") {
            elBtnHeart.className = "btn--heart btn--heartActive";
            elBtnHeart.setAttribute("aria-label", "Usuń do ulubionych");
        }
        elLi.appendChild(elBtnHeart);
        favouriteAdd(elBtnHeart);

        const swiper = new Swiper(newFullWidthOuther, {
            lazy: true,
            loop: true,
            keyboard: true,
            slidesPerView: 1.1,
            spaceBetween: 20,

            navigation: {
                nextEl: sbNext,
                prevEl: sbPrev,
            },
            slideActiveClass: 'swiper-slide-active',
            breakpoints: {
                640: {
                    slidesPerView: 2.2,
                },
                1200: {
                    spaceBetween: 30,
                    slidesPerView: 3.5,
                }
            }
        });
        sbPrev.addEventListener("click", function (e) {
            e.stopPropagation();
        });
        sbNext.addEventListener("click", function (e) {
            e.stopPropagation();
        });


        setTimeout(function () {
            elLi.classList.remove("product__item--op0");
        }, time);
    }

    wrapper.prepend(newFullWidthOuther);

    if(initTabsOffert==0){
        initTabsOffert=1;

        setTimeout(function () {
            if(panel.offsetHeight<800)
                panel.style.minHeight="auto";
            panel.style.minHeight=panel.offsetHeight+"px";
        }, 1000);
    }

}


//dodawanie produktów do tab-strona główna


function createTabElements(data, el) {

    let id = el.getAttribute("aria-controls");

    let wrapper = document.getElementById(id);

    let products = JSON.parse(data);

    if (products.length > 0) {
        if (wrapper.querySelector(".alert-tab")) {
            wrapper.querySelector(".alert-tab").remove();
        }

        createSimpleElemnts(products, wrapper, "swiper-slide product__slide product__item product__item--op0");

    } else {
        if (wrapper.querySelector(".btn-all-cat")) {
            wrapper.querySelector(".btn-all-cat").remove();
        }
        let noEl = document.createElement("div");
        noEl.className = "alert-tab container text-center bold mt-30 main";
        noEl.textContent = "W wybranej przez Ciebie kategorii nie ma jeszcze wycieczek";
        wrapper.prepend(noEl);
    }


}

if (document.querySelectorAll(".trip__nav-trigger")) {
    let tabBtns = document.querySelectorAll("#nasza-oferta .trip__nav-trigger");
    for (var i = 0; i < tabBtns.length; i++) {
        tabBtns[i].addEventListener("click", function (e) {
            if (!this.classList.contains("js--add")) {
                let el = this;
                let category = this.getAttribute("data-category");

                let token = document.querySelector("meta[name='csrf-token']").getAttribute("content");
                dataToSend = new FormData();
                dataToSend.set('kategoria', category);
                dataToSend.set('__csrf__token__', token);
                makeRequest('/a/?a=ofertyStronaGlowna&kategoria=' + category, "GET", dataToSend, createTabElements, el);
                this.classList.add("js--add");
            }
        });

        if (!tabBtns[0].classList.contains("js--add")) {
            tabBtns[0].click();
        }
    }
}



function onWindowResize() {
    panel.style.minHeight="auto";
    panel.style.minHeight=panel.offsetHeight+"px";
}

// Nasłuchiwanie na zdarzenie zmiany rozmiaru okna
window.addEventListener('resize', onWindowResize);
